<template>
  <div>
    <div class="items" @click="toInfo(item)" v-for="(item, index) in curDataList" :key="item.id">
      <p v-if="item.isEffective == false || (!item.isControlled && type == 'cg')" class="unshelf">{{!item.isControlled ? '已失效' : '已下架'}}</p>
      <p v-if="item.isEffective == false || (!item.isControlled && type == 'cg')" class="unshelf_goods"></p>
      <div class="goods" >
        <!-- abc.png -->
        <img :src="item.pic" alt="">
      </div>
      <p class="title">{{item.productName}}</p>
      <p class="types">{{item.productSkuDTO.properties.value}}</p>
      <div class="myselfHeight">
        <p class="desc">{{item.paramsValueList?item.paramsValueList[0].paramsValue : item.listParamsValue[0].paramsValue }}</p>
        <p class="desc">零售价¥{{item.productSkuDTO.retailPrice}} 
          <span v-if="item.productSkuDTO.accordScope">| 毛利{{item.productSkuDTO.policyScope ? item.productSkuDTO.grossProfitRate : '**'}}</span> </p>
      </div>
      <p class="price">{{item.productSkuDTO.policyScope && item.productSkuDTO.accordScope ? "¥" + item.productSkuDTO.salePrice : ''}}</p>
      <p class="mrt5" style="height: 18px;margin-bottom:15px">
        <span class="mz" v-if="item.isFullGift && item.productSkuDTO.policyScope && item.productSkuDTO.accordScope">满赠</span>
      </p>
      <div v-if="item.productSkuDTO.policyScope && item.productSkuDTO.accordScope" class="opts" @click="(e) => e.stopPropagation()">
        <div class="nums">
          <img @click="minusClick(item)" src="@/assets/img/mus.png" alt="">
          <input style="width: 35px;text-align: center" v-model="item.goodsNum">
          <img @click="plusClick(item)" src="@/assets/img/add.png" alt="">
        </div>
        <button v-if="type == 'bk' || (type == 'cg' && item.isEffective)" @click="addOrder(item, index)">加入清单</button>
        <button v-else class="grayBtn" >已售罄</button>
      </div>
      <div class="zanwu" v-else>
        <span v-if="!item.productSkuDTO.accordScope">超出经营范围</span>
        <span v-else>暂无价格</span>
      </div>
      <div class="alreadyBuy" v-if="item.alreadyBuy && item.isEffective != false">已加购{{item.alreadyBuy}}件</div>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/utils/bus";
export default {
  name: "footerMenu",
  props: ['isMz', 'dataList','type'],
  data(){
    return{
     goodsNum: 1,
     curDataList: [],
     orderCarList: [],
    }
  },
  watch: {
    dataList: {
      handler(newVal, oldVal){
        let curStorageGoods = JSON.parse(localStorage.getItem('orderCarList')) || [];
        // console.log('oldValoldValval==>', oldVal,newVal)
        this.curDataList = newVal && newVal.map(item => {
          if (!item.goodsNum) {
            item.goodsNum = item.productSkuDTO.minBuy;
          }
          curStorageGoods.map(item2 => {
            if (item.productSkuDTO.productSkuId === item2.productSkuId) {
              item.alreadyBuy = item2.goodsNum;
            }
          })
          return item;
        })
      },
      deep: true
    },
  },
  created(){
    // console.log(this.isMz)
  },
  methods:{
    addOrder(info, index){
      if (!info.productSkuDTO.policyScope  && !info.productSkuDTO.accordScope){
        return this.$message.error('该商品未配置价格政策，不能购买');
      }
      let orderCarList = JSON.parse(localStorage.getItem('orderCarList')) || [];
      let curIndex = -1
      let curInfo = orderCarList.filter((item, index) => {
        if (item.productSkuId === info.productSkuDTO.productSkuId) {
          item.goodsNum = item.goodsNum - 0;
          info.goodsNum = info.goodsNum - 0;
          item.goodsNum += info.goodsNum;
          info.alreadyBuy = item.goodsNum;
          curIndex = index;
          return {
            goodsNum: item.goodsNum,
            productSkuId: item.productSkuId,
            productName: item.productName,
            productId: info.productSkuDTO.productId,
          }
        }
      })
      if (curInfo.length > 0) {
        orderCarList.splice(curIndex, 1)
        orderCarList.push(curInfo[0])
      } else {
        orderCarList.push({
            goodsNum: info.goodsNum,
            productSkuId: info.productSkuDTO.productSkuId,
            productName: info.productName,
            productId: info.productSkuDTO.productId,
          })
        info.alreadyBuy = info.goodsNum - 0; 
      }
      EventBus.$emit("goodsLength", orderCarList.length);
      this.$notify({
        title: '已加入订购清单',
        message: '',
        type: 'success'
      });
      localStorage.setItem('orderCarList', JSON.stringify(orderCarList))
      this.$set(this.curDataList, index, info)
    },
    minusClick(item){
      if (item.goodsNum <= item.productSkuDTO.minBuy) {
        return this.$notify({
        title: '购买数量不能小于最小购买数量',
        type: 'info'
      });
      }
      item.goodsNum -= 1;
      this.$forceUpdate();
    },
    plusClick(item){
      item.goodsNum = item.goodsNum - 0;
      item.goodsNum += 1; 
      this.$forceUpdate();
    },
    toInfo(item){
      if(this.type == 'cg' && !item.isControlled){
        return false;
      }
      if(this.type == 'bk' || (this.type == 'cg' && item.isEffective)){
        this.$router.push({
          path:'/GoodsInfo',
          query:{
            id: item.productId,
            isMz: item.isFullGift ? 1 : 0,
          }
        })
      }
    }
  }
};
</script>
<style lang="less" scoped>
.items{
  background: #fff;
  width: 190px;
  margin-bottom: 12px;
  box-sizing: border-box;
  margin-right: 12px;
  padding: 0 10px 12px;
  cursor: pointer;
  border-radius: 6px;
  position: relative;
  &:nth-of-type(6n+0){margin-right: 0;}
  .goods{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    img{
      width: 120px;
      height: 120px;
    }
  }
  .title{
    font-size: 14px;
    font-family: "Alibaba-PuHuiTi-M, Alibaba-PuHuiTi";
    font-weight: bold;
    color: #222;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .types{
    font-size: 14px;
    font-family: "Alibaba-PuHuiTi-M, Alibaba-PuHuiTi";
    font-weight: bold;
    color:rgba(0,0,0,0.7);
    margin-bottom: 5px;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .desc{
    font-size: 12px;
    font-family: "Alibaba-PuHuiTi-R, Alibaba-PuHuiTi";
    font-weight: normal;
    color: #97A0AA;
    line-height: 16px;
    margin-bottom: 3px;
    word-break: break-all;
  }
  .price{
    font-size: 20px;
    font-family: "DIN-Bold, DIN";
    font-weight: bold;
    color: #FC5D2C;
  }
  .mz{
    display: inline-block;
    width: 32px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #FC5D2C;
    font-size: 12px;
    font-weight: normal;
    color: #FC5D2C;
  }
  .opts{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    .nums{
      img{
        cursor: pointer;
      }
      span{
        width: 30px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background: #F8F8F8;
        margin: 0 10px;
        border-radius: 6px;
      }
      input{
        background: #F8F8F8;
        height: 20px;
        margin: 0 10px;
        border-radius: 6px;
      }
    }
    div{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    button{
      width: 68px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: @base-color;
      border:none;
      border-radius: 2px;
      cursor: pointer;
      color:#fff;
      font-size: 12px;
      &:hover{
        background: @base-hcolor;
      }
    }
    .grayBtn{
      background: #F7F8FA;
      color: #B2BECB;
      cursor:not-allowed;
    }
  }
}
.mrt5{
  margin-top: 5px;
}
.myselfHeight{
  min-height: 55px;
}
.alreadyBuy{
  margin-top: 10px;
  color:#2AC69D;
  font-size: 14px;
}
.zanwu{
  color:#f30;
  font-size: 18px;
}
.unshelf{
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top:23%;
  right: 15px;
  z-index: 8;
  color:#FF7865;
  border:2px solid #FF7865;
  font-size: 18px;
  font-weight: bold;
  transform:rotate(333deg)
}
.unshelf_goods{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .3);
  z-index: 9999;
  cursor:not-allowed;
}
</style>