import axios from 'axios';
import {Message, Loading} from 'element-ui';
import { saveAs } from 'file-saver'
import { tansParams, blobValidate } from "@/utils/tools";

const service = axios.create({
    // headers: {token: ''},
    timeout: 5000
});
let downloadLoadingInstance;
service.interceptors.request.use(
    
    config => {
        let token = localStorage.getItem('token')
        // console.log('config.url', config.url)
        if (config.url.indexOf('/platform/api/tool/appVersionInfo/get') === -1){
            // /platform/api/tool/appVersionInfo/get
            config.headers.Authorization = "bearer " + token || undefined
        }
        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url = config.url + '?' + tansParams(config.params);
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }
        // console.log('configconfig', config)
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        // console.log('response', response.headers['content-disposition'])
        if (response.status === 200) {
            if(response.headers['content-type'].indexOf('octet-stream') > -1){
                return response.data
              }
            // console.log('response.data.code', response.data.code)
            if (response.data.code === 401) {
            }
            if (response.data.code === 0) {
                return response.data;
            } else {
                Message.error(response.data.message);
                return Promise.reject(response.data);
            }
        } else {
            Message.error(response.data.message);
            Promise.reject(response);
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);
// 通用下载方法
export function download(url, filename) {
    downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
    return service.get(url, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob'
    }).then(async (data) => {
      const isLogin = await blobValidate(data);
      if (isLogin) {
        const blob = new Blob([data])
        saveAs(blob, filename)
      } else {
        const resText = await data.text();
        const rspObj = JSON.parse(resText);
        const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
        Message.error(errMsg);
      }
      downloadLoadingInstance.close();
    }).catch((r) => {
      console.error(r)
      Message.error('下载文件出现错误，请联系管理员！')
      downloadLoadingInstance.close();
    })
  }

export default service;
