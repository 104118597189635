<template>
  <div class="search">
    <div class="content">
      <img class='loginIcon'  @click="toIndex" src="@/assets/img/login.png" alt="">
      <div class="searchInput">
        <div class="leftCon">
          <div @click="openTables">
            <img src="@/assets/img/searchIcon.png" alt="">
            <el-input placeholder='搜索药品/品牌名称' @keyup.enter.native="searchGoods" clearable v-model="searchValue" type="text" />
          </div>
          <span @click="searchGoods">搜索</span>
        </div>
        <div class="rightCon" @click="toOrderList">
          <img src="@/assets/img/orderIcon.png" alt="">
          <span>订购清单</span>
          <span>{{goodsNum}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/utils/bus";
export default {
  name: "searchCon",
  props: {
    msg: String,
  },
  data(){
    return{
      searchValue: '',
      goodsNum: 0,
    }
  },
  created(){
    this.goodsNum = localStorage.getItem('orderCarList') ? JSON.parse(localStorage.getItem('orderCarList')).length : 0
  },
  mounted(){
    EventBus.$on("goodsLength", goodsLength => {
        this.goodsNum = goodsLength;
      })
  },
  methods:{
    searchGoods(){
      this.$router.push({
        path:"/searchResult1",
        query:{
          categoryId:'',
          categoryName:this.searchValue,
          _t:Date.now()
        }
      });
    },
    openTables(){

    },
    toIndex(){
      this.$router.push({
        path:"/home"
      })
    },
    toOrderList(){
      this.$router.push("/MyOrderList");
    },
  }
};
</script>
<style lang="less" scoped>
  .search{
    height: 96px;
    background: #fff;
    width: 100%;
    display: flex;
    min-width: @base-width;
    align-items: center;
    .content{
      width: @base-width;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .loginIcon{
        width: 174px;
        height: 47px;
        cursor: pointer;
      }
      .searchInput{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 20px;
        .leftCon{
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 36px;
          width: 600px;
          border-radius: 2px;
          border: 2px solid @base-color;
          box-sizing: border-box;
          padding-left: 14px;
          position: relative;
          div{
            width: calc(100% - 53px);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            /deep/.el-input__inner{
              padding: 0 10px;
              height: 33px;
              line-height: 31px;
              width: 100%;
              border:none;
            }
            input::-webkit-input-placeholder{ /* WebKit browsers */
              color: #B2BECB;
            }
            input:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
              color: #B2BECB;
            }
            input::-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
              color: #B2BECB;
            }
            input::-ms-input-placeholder{/* Internet Explorer 10+ */
              color: #B2BECB;
            }
          }
          span{
            position: absolute;
            right: -2px;
            top: -1px;
            display: inline-block;
            background: @base-color;
            width: 103px;
            height: 33px;
            line-height: 33px;
            font-size: 16px;
            color:#fff;
            text-align: center;
            border-bottom-right-radius: 2px;
            border-top-right-radius: 2px;
            cursor: pointer;
            &:hover{
              background:@base-hcolor;
            }
          }
        }
        .rightCon{
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 132px;
          height: 36px;
          background: #FEEEE9;
          color: #FC5D2C;
          box-sizing: border-box;
          margin-left: 12px;
          padding: 15px;
          cursor: pointer;
          img{
            margin-right: 6px;
          }
          span{
            margin-right: 6px;
            &:nth-child(3){
              color:#fff;
              background: #FC5D2C;
              border-radius:20px;
              padding: 2px 8px;
            }
          }
        }
      }
    }
  }

</style>