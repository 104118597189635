<template>
  <div :class="className" @mouseleave="isShowList = false">
    <ul class="list">
      <li :class="currentId == index ? 'cleckOnCss' : '' " v-for="(items,index) in categoryList" :key="index"
      v-show="index < 5"  @click="changeLi(index)" @mouseenter="mouseClick(true,items)">
        <div class="list-div">
          <img :src="iconArr[index]" alt="">
          <span>{{items.categoryName}}</span>
        </div>
        <img src="@/assets/img/rightIcon.png" alt="">
      </li>
      <li :class="currentId == 5 ? 'cleckOnCss' : '' "   @click="changeLi(5)" @mouseenter="mouseClick(true,otherArr)">
        <div class="list-div">
          <img :src="iconArr[5]" alt="">
          <span>其他</span>
        </div>
        <img src="@/assets/img/rightIcon.png" alt="">
      </li>
    </ul>
    <!-- 二级分类start v-show="isShowList"-->
    <div class="classifyDiv" v-show="isShowList">
      <div v-for="(item,index) in updateotherArr" :key="index">
        <p class="otherp" @click="toResult(item)" v-if="showName">
          <i>{{item.categoryName}}</i><img src="@/assets/img/rightIcon.png" alt="">
        </p> 
        <div class="borderBom" v-if="categoryPageDataList.length >0 && !showName">
          <span class="productName" v-for="(items,index) in categoryPageDataList" :key="index" style="cursor: pointer;" @click="toInfo(items)"> 
            <i v-if="showName">|</i>
            {{items.productName}}
          </span>
        </div>
      </div>
    </div>
    <!-- 二级分类 end-->
  </div>
</template>
<script>
import apiModel from "@/api";
export default {
  name: "goodClassify",
  props: ['categoryList','className'],
  data(){
    return{
      showThisPage:false,
      currentId:-1,
      defaultImgurl:require("@/assets/img/qt.png"),
      iconArr:[
        require("@/assets/img/zy.png"),
        require("@/assets/img/zcy.png"),
        require("@/assets/img/yybj.png"),
        require("@/assets/img/jyjz.png"),
        require("@/assets/img/ylqx.png"),
        require("@/assets/img/qt.png"),
      ],
      isShowList:false,
      otherArr:[],
      updateotherArr:[],
      showName:false,
      categoryPageDataList:[]
      // list:[
      //   {name:"中药",icon:require("@/assets/img/homeIcon.png")},
      //   {name:"中成药",icon:require("@/assets/img/homeIcon.png")},
      //   {name:"营养保健",icon:require("@/assets/img/homeIcon.png")},
      //   {name:"降压降脂",icon:require("@/assets/img/homeIcon.png")},
      //   {name:"医疗器械",icon:require("@/assets/img/homeIcon.png")},
      //   {name:"其他",icon:require("@/assets/img/homeIcon.png")}
      // ]
    }
  },
  methods:{
    categoryPageData(categoryId) {
      apiModel.categoryPageData({categoryId: categoryId}).then(async (res) => {
        this.categoryPageDataList = res.data.list;
      });
    },
    toInfo(info){
      this.isShowList = false;
      this.$router.push({
        path:'/GoodsInfo',
        query:{
          id: info.productId,
          isMz: info.isFullGift ? 1 : 0,
        }
      })
    },
    toResult(info){
      this.isShowList = false;
      this.$router.push({
        path:"/searchResult",
        query:{
          categoryId:info.id,
          _t:Date.now()
        }
      });
    },
    changeLi(index){
      this.currentId = index;
      this.isShowList = true;
    },
    mouseClick(flag,datas){
      this.categoryPageDataList = [];
      if(!datas.categoryName){
        this.showName=true;
        this.categoryPageDataList = [];
      }else{
        this.categoryPageData(datas.id)
        this.showName=false;
      } 
      this.isShowList = flag;
      if(datas instanceof Array){
        this.updateotherArr = datas;
      }else{
        this.updateotherArr = [datas];
      }
    }
  },
  watch:{
    categoryList(val){
      this.otherArr = val.slice(5,val.length);
    }
  },
  created(){
    this.otherArr = this.categoryList.slice(5,this.categoryList.length);
    //  apiModel.listAllTree({categoryType: 0}).then(res => {
    //   console.log('listAlllistAll==>', res.data)
    //   this.categoryList = res.data
    // })
  },

};
</script>
<style lang="less" scoped>

.list{
  width: 240px;
  border-left: 1px solid #EAEBEF;
  height: 360px;
  max-height: 360px;
  // overflow-y: scroll;
  .checkedLi{
    background: #F7F8FC;
    color:@base-color;
  }
  li{
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EAEBEF;
    padding: 0 27px;
    box-sizing: border-box;
    cursor: pointer;
    // .classifyDiv{
    //   position: absolute;
    //   top:0;
    //   left: 241px;
    //   z-index: 99;
    //   width: 960px;
    //   height: 361px;
    //   background: #F7F8FC;
    //   padding: 24px;
    //   box-sizing: border-box;
    //   div{
    //     display: flex;
    //     align-items: center;
    //     width: 100% !important;
    //     flex-wrap: wrap;
    //     height: 42px;
    //     .borderBom{
    //       width: 100%;
    //       border-bottom: 2px dashed #ECEFF3;
    //       span{
    //         font-size: 14px;
    //         color:#f30;
    //         margin-right: 8px;
    //         &:hover{
    //           color:#2AC69D;
    //         }
    //       }
    //     }
        .otherp{
          display: flex;
          align-items: center;
          cursor: pointer;
          i{
            font-style: normal;
            font-size: 14px;
            color:#222;
            display: inline-block;
            width: 70px;
          }
          img{
            margin-right: 5px;
          }
        }
    //     span{
    //       display: flex;
    //       justify-content: flex-start;
    //       align-items: center;
    //       color: #575C61;
    //       font-size: 14px;
    //       cursor: pointer;
    //       // &:nth-child(1){
    //       //   font-size: 14px;
    //       //   color:#222;
    //       // }
    //       i{
    //         margin-right:8px;
    //         font-style: normal;
    //         color:#B2BECB;
    //       }
    //       img{
    //         margin: 0 16px;
    //       }
    //     }
    //   }
    // }

    &:hover{
      background: #F7F8FC;
      color:@base-color;
    }
    &.cleckOnCss{
      background: #F7F8FC;
      color:@base-color;
    }
    .list-div{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img{
        margin-right: 6px;
        width: 24px;
        height: 24px;
      }
    }
  }
}

 .classifyDiv{
      position: absolute;
      top:0;
      left: 241px;
      z-index: 99;
      width: 960px;
      height: 361px;
      background: #F7F8FC;
      padding: 24px;
      box-sizing: border-box;
      div{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        height: 42px;
        width: 100%;
        .borderBom{
          // border-bottom: 2px dashed #ECEFF3;
          span{
            font-size: 14px;
            color:#575C61;
            margin-right: 10px;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 2px dashed #ECEFF3;
            width: 255px;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover{
              color:#2AC69D;
            }
          }
          i{
            margin-right:8px;
            font-style: normal;
            color:#B2BECB;
          }
        }
        .otherp{
          display: flex;
          align-items: center;
          cursor: pointer;
          i{
            font-style: normal;
            font-size: 14px;
            color:#222;
            display: inline-block;
            width: 70px;
          }
          img{
            margin-right: 5px;
          }
        }
        // span{
        //   display: flex;
        //   justify-content: flex-start;
        //   align-items: center;
        //   color: #575C61;
        //   font-size: 14px;
        //   margin-bottom: 10px;
        //   &:nth-child(1){
        //     font-size: 14px;
        //     color:#222;
        //     display: inline-block;
        //     // width: 100px;
        //   }
        //   i{
        //     margin-right:8px;
        //     font-style: normal;
        //     color:#B2BECB;
        //   }
        //   img{
        //     margin: 0 16px;
        //   }
        // }
      }
    }

  .xfClass{
    position:absolute;
    left: 0;
    top:48px;
    background: #fff;
    z-index: 9;
    box-shadow: 0px 2px 8px 5px #ddd;
    .classifyDiv{
      box-shadow: 0px 2px 8px 5px #ddd;
    }
  }
  ul,li{
      list-style: none;
  }
</style>