import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import apiModel from "./api";
import request from './utils/request';
import { download } from '@/utils/request'
import ElementUI from "element-ui";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/base.css";
import "./assets/variable.less";
Vue.use(ElementUI);
const config = {
  realm: process.env.VUE_APP_SSO_REALM || "dinuo",
  url: process.env.VUE_APP_SSO_URL || "https://passport.dinuo.com.cn/auth",
  clientId: process.env.VUE_APP_SSO_CLIENT_ID || "pc-pages",
};
Vue.use(VueKeyCloak, {
  init: {
    onLoad: "login-required",
    checkLoginIframe: false,
  },
  config,
  onReady,
});

(async () => {
  try {
    window.localStorage.removeItem('requestUrlData') // 先清理，避免缓存导致环境一致无法切换
    let appBaseUrl = await apiModel.getApiPrefixFromStorage('appBaseUrl')
  } catch (error) {
    let response = await apiModel.appVersionInfo()
    window.localStorage.setItem('requestUrlData', JSON.stringify(response.data.requestUrlData))
  }
})();

Vue.prototype.$request = request;
Vue.prototype.$download = download;
Vue.config.productionTip = false;
function onReady() {
  new Vue({
    router,
    store,
    render: (h) => h(App),
    async created() {
      let that = this;
      const onAuthSuccess = async function () {
        // 这个回调做任何登录之后的 初始化动作
        const currentUser = that.$keycloak.tokenParsed;
        localStorage.setItem('token', that.$keycloak.token)
        localStorage.setItem('userInfo', JSON.stringify(currentUser))
      };
        onAuthSuccess();
        this.$keycloak.keycloak.onAuthSuccess = onAuthSuccess;
       
    },
  }).$mount("#app");
}
