import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue"; //首页

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/Home",
    name: "home",
    component: Home,
  },
  {
    path: "/GoodsInfo", //商品详情
    name: "goodsInfo",
    component: () =>
      import(/* webpackChunkName: "goodsInfo" */ "../views/GoodsInfo.vue"),
  },
  {
    path: "/QuickOrder", //快速下单
    name: "quickOrder",
    component: () =>
      import(/* webpackChunkName: "quickOrder" */ "../views/QuickOrder.vue"),
  },
  {
    path: "/SearchResult", //搜索结果页
    name: "searchResult",
    component: () =>
      import(/* webpackChunkName: "searchResult" */ "../views/SearchResult.vue"),
  },
  {
    path: "/SearchResult1", //搜索结果页
    name: "searchResult1",
    component: () =>
      import(/* webpackChunkName: "searchResult1" */ "../views/SearchResult1.vue"),
  },
  {
    path: "/MyOrderList", //我的订单信息
    name: "myOrderList",
    component: () =>
      import(/* webpackChunkName: "myOrderList" */ "../views/MyOrderList.vue"),
  },
  {
    path: "/Protocol", //隐私协议
    name: "protocol",
    component: () =>
      import(/* webpackChunkName: "protocol" */ "../views/Protocol.vue"),
  },
  {
    path: "/CheckOrderInfo", //核对订单信息
    name: "checkOrderInfo",
    component: () =>
      import(/* webpackChunkName: "checkOrderInfo" */ "../views/CheckOrderInfo.vue"),
  },
  {
    path: "/Activity",//活动专区
    name: "activity",
    component: () =>
      import(/* webpackChunkName: "activity" */ "../views/Activitys.vue"),
  },
  {
    path: "/Material", //宣传物料
    name: "material",
    component: () =>
      import(/* webpackChunkName: "material" */ "../views/XcMaterial.vue"),
  },
  {
    path: "/AboutMe", //关于我们
    name: "aboutMe",
    component: () =>
      import(/* webpackChunkName: "aboutMe" */ "../views/AboutMe.vue"),
  },
  {
    path: "/PaySuccess", //支付成功
    name: "paySuccess",
    component: () =>
      import(/* webpackChunkName: "paySuccess" */ "../views/PaySuccess.vue"),
  },
  {
    path: "/Payment", //支付
    name: "payment",
    component: () =>
      import(/* webpackChunkName: "payment" */ "../views/Payment.vue"),
  },
  {
    path: "/PaySelect", //支付选择
    name: "paySelect",
    component: () =>
      import(/* webpackChunkName: "paySelect" */ "../views/PaySelect.vue"),
  },
  {
    path: "/PersonalCenter", //个人中心
    name: "personalCenter",
    redirect:"/PersonalCenter/Myorder",
    component: () => import(/* webpackChunkName: "personalCenter" */ "../views/PersonalCenter.vue"),
    children:[
      {
        path: "Myorder", //我的订单
        name: "myorder",
        component: () =>
          import(/* webpackChunkName: "myorder" */ "../views/personalCenterPage/MyOrder.vue"),
      },
      {
        path: "OrderInfo", //订单详情
        name: "orderInfo",
        component: () =>
          import(/* webpackChunkName: "orderInfo" */ "../views/personalCenterPage/OrderInfo.vue"),
      },
      {
        path: "MyMoney", //我的余额
        name: "mymoney",
        component: () =>
          import(/* webpackChunkName: "mymoney" */ "../views/personalCenterPage/MyMoney.vue"),
      },
      {
        path: "MyAddress", //我的收货地址
        name: "myAddress",
        component: () =>
          import(/* webpackChunkName: "myAddress" */ "../views/personalCenterPage/MyAddress.vue"),
      },
      {
        path: "MyIntegral", //我的收货地址
        name: "myIntegral",
        component: () =>
          import(/* webpackChunkName: "myIntegral" */ "../views/personalCenterPage/MyIntegral.vue"),
      },
      {
        path: "MessageCenter", //消息中心
        name: "messageCenter",
        component: () =>
          import(/* webpackChunkName: "messageCenter" */ "../views/personalCenterPage/MessageCenter.vue"),
      },
      {
        path: "SalesRules", //售后规则
        name: "salesRules",
        component: () =>
          import(/* webpackChunkName: "salesRules" */ "../views/personalCenterPage/SalesRules.vue"),
      },
      {
        path: "GoodsSyzl", //商品首营资料
        name: "GoodsSyzl",
        component: () =>
          import(/* webpackChunkName: "goodsSyzl" */ "../views/personalCenterPage/GoodsSyzl.vue"),
      },
      {
        path: "GoodsCjbg", //商品厂检报告
        name: "GoodsCjbg",
        component: () =>
          import(/* webpackChunkName: "goodsCjbg" */ "../views/personalCenterPage/GoodsCjbg.vue"),
      },
      {
        path: "AccountSettings", //账号设置
        name: "accountSettings",
        component: () =>
          import(/* webpackChunkName: "accountSettings" */ "../views/personalCenterPage/AccountSettings.vue"),
      },
      {
        path: "SalesAdvisor", //我的销售顾问
        name: "salesAdvisor",
        component: () =>
          import(/* webpackChunkName: "salesAdvisor" */ "../views/personalCenterPage/SalesAdvisor.vue"),
      },
      {
        path: "PersonalData", //我的销售顾问
        name: "personalData",
        component: () =>
          import(/* webpackChunkName: "personalData" */ "../views/personalCenterPage/PersonalData.vue"),
      },
      {
        path: "UsuallyBuy", //常购商品
        name: "usuallyBuy",
        component: () =>
          import(/* webpackChunkName: "usuallyBuy" */ "../views/personalCenterPage/UsuallyBuy.vue"),
      },
      {
        path: "CollectionGoods", //收藏商品
        name: "collectionGoods",
        component: () =>
          import(/* webpackChunkName: "collectionGoods" */ "../views/personalCenterPage/CollectionGoods.vue"),
      },
      {
        path: "FinanceInfo", //财务信息
        name: "financeInfo",
        component: () =>
          import(/* webpackChunkName: "financeInfo" */ "../views/personalCenterPage/FinanceInfo.vue"),
      },
      {
        path: "QualificationCertification", //资质认证
        name: "qualificationCertification",
        component: () =>
          import(/* webpackChunkName: "qualificationCertification" */ "../views/personalCenterPage/QualificationCertification.vue"),
      },
    ]
  },
 
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
