<template>
  <div @mouseleave="mouseClick(false)">
    <div class="mainMenu">
      <div class="mainMenuContent">
        <goodClassify  v-if="currentid !=0 && ishow"  :className="'xfClass'" :categoryList="categoryList" />
        <div class="mainMenuContent_div" @mouseenter="mouseClick(true)">
          <img src="@/assets/img/type.png" alt="">
          全部商品分类
          <!-- -->
        </div>
        <ul class="mainMenuContent_ul">
          <li v-for="(item,index) in menuList" :key="index" :class="currentid ==  index ? 'checkedLi':''" @click="changePages(index,item)">
            <router-link :to="item.url">{{item.name}}</router-link> 
          </li>
        </ul>
      </div>
    </div>
    
  </div>
</template>
<script>
import { EventBus } from "@/utils/bus";
import apiModel from "@/api";
import goodClassify from "@/components/goodClassify.vue";
export default {
  name: "searchCon",
  props: {
    msg: String,
  },
  components:{
    goodClassify
  },
  data(){
    return{
      ishow:false,
      categoryList: [],
      currentid:0,
      menuList:[
        {name:"首页",url:'/home'},
        {name:"快速下单",url:'/quickOrder'},
        {name:"活动专区",url:'/activity'},
        {name:"宣传物料",url:'/material'},
        {name:"关于我们",url:'/aboutMe'},
      ]
    }
  },
   watch: {
    $route(to, from) {
      this.getCurrentUrlValue(to.fullPath)
    }
  },
  created(){
    this.getListAllTree();
  },
  mounted(){
    
  },
  methods:{
    mouseClick(val){
      this.ishow = val;
    },
    getListAllTree() {
      apiModel.listAllTree({ categoryType: 0 }).then((res) => {
        this.categoryList = res.data;
      });
    },
    getCurrentUrlValue(url){
      this.currentid = this.menuList.findIndex(item => item.url == url)
    },
    changePages(index,datas){
      this.currentid = index;
      this.$store.commit('CRMB',datas);
    }
  }
};
</script>
<style lang="less" scoped>
.mainMenu{
  width: 100%;
  min-width: @base-width;
  height: 48px;
  background: @base-color;
  display: flex;
  position: relative;
  .mainMenuContent{
    width:@base-width;
    margin: 0 auto;
    height: 48px;
    display: flex;
    align-items: center;
    // color:#fff;
    position: relative;
    .mainMenuContent_div{
      color:#fff;
      display: flex;
      align-items: center;
      width: 240px;
      padding-left: 27px;
      box-sizing: border-box;
      font-size: 16px;
      font-family: "Alibaba-PuHuiTi-R, Alibaba-PuHuiTi";
      font-weight: bold;
      cursor: pointer;
      img{
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }
    ul,li{
      list-style: none;
    }
    .mainMenuContent_ul{
      display: flex;
      justify-content: flex-start;
      .checkedLi{
        background: rgba(0,0,0,0.1);
        width: 120px;
        font-size: 16px;
      }
      li{
        width: 120px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 16px;
        cursor: pointer;
        a{
          display: inline-block;
           width: 120px;
          height: 48px;
          color:#fff;
          text-decoration:none;
        }
      }
    }
  }
}
ul,li{
      list-style: none;
    }
</style>