<template>
  <div class="topMenu">
    <div class="topMenuContent">
      <div class="left">
        <img src="@/assets/img/homeIcon.png" alt="">
        <span>{{customerName}}</span>
        <span @click="logout">退出</span>
      </div>
      <ul class="rightUl">
        <li v-for="(item,index) in menuList" :key="index" @click="toUrl(item.url,item)">{{item.name}}</li>
      </ul>
    </div>
    
  </div>
</template>
<script>
import apiModel from '@/api';
export default {
  name: "topMenu",
  props: {
    msg: String,
  },
  data(){
    return{
      customerName:'',
      menuList:[
        {name:'首页',url:'/home'},
        {name:'个人中心',url:'/PersonalCenter'},
        {name:'我的订单',url:'/PersonalCenter/Myorder'},
        {name:'我的消息',url:'/PersonalCenter/MessageCenter'},
        {name:'联系我们',url:'/aboutMe'}
      ]
    }
  },
  computed: {
  },
  methods:{
    toUrl(url,datas){
      this.$store.commit('CRMB',datas);
      this.$router.push({
        path:url
      })
    },
    logout(){
      if (this.$keycloak && this.$keycloak.keycloak) {
        this.$keycloak.keycloak.logout();
        localStorage.clear()
      }
    }
  },
  created () {
    apiModel.myProfileDatas({}).then(res => {
      this.customerName = res.data.customerName;
      localStorage.setItem('fullUserInfo',JSON.stringify(res.data))
    });
  }
};
</script>
<style lang="less" scoped>
  .topMenu{
    width: 100%;
    min-width: @base-width;
    height: 42px;
    background: #F8F8F8;
    border-bottom: 1px solid #EAEBEF;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .topMenuContent{
      width: @base-width;
      height: 42px;
      max-width: @base-width;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
    }
    .left{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span{
        margin-left: 5px;
      }
      span:nth-child(2){
        color: #222;
      }
      span:nth-child(3){
        color: #97A0AA;
        cursor: pointer;
      }
    }
    ul,li{
      list-style: none;
    }
    .rightUl{
      display: flex;
      justify-content: space-around;
      width: 30%;
      li{
        cursor: pointer;
        color:#222;
        &:hover{
          color:#2ac69d;
        }
      }
    }
  }
  ul,li{
      list-style: none;
    }
</style>