<template>
  <div class="banner">
    <el-carousel :interval="10000">
      <el-carousel-item v-for="(item,index) in bannerList" :key="index">
        <img class="bannerImg" :src="item.imgUrl" alt="" @click="jumpClick(item)">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: "bannerCarousel",
  props: [
    "bannerList"
  ],
  data(){
    return{
    }
  },
  methods: {
    jumpClick(info){
      location.href = info.linkUrl
    }
  }
};
</script>
<style lang="less" scoped>
.bannerImg{
  width: 960px;
  height: 360px;
}
.banner{
  width: 960px;
  height: 360px;
}
/deep/.el-carousel{
   width: 960px;
  height: 360px;
}
/deep/.el-carousel__item{
  width: 960px;
  height: 360px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
/deep/.el-carousel__button{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid rgba(255,255,255,0.6);
}
</style>