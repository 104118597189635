<template>
  <div id="app">
    <topMenu />
    <searchKs v-if="isSearchks" />
    <searchCon v-if="isSearchCon" />
    <mainMenu v-show="isShowmainMenu" />
    <div class="centerPages">
      <router-view />
    </div>
    <footerMenu v-show="isShowfooterMenu" />
  </div>
</template>

<script>
import apiModel from "@/api";
import topMenu from "@/components/topMenu.vue";
import footerMenu from "@/components/footerMenu.vue";
import searchKs from "@/components/searchKs.vue"; //搜索组件
import searchCon from "@/components/searchCon.vue"; //搜索组件
import mainMenu from "@/components/mainMenu.vue";
const disPlayArr = ["quickOrder"];
export default {
  name: "App",
  components: {
    topMenu,
    footerMenu,
    searchCon,
    searchKs,
    mainMenu,
  },
  data(){
    return{
      num : 10,
      isSearchCon:true,
      isShowfooterMenu:true,
      isShowmainMenu:true,
      isSearchks:false,
      currentUrl:this.$route.fullPath,
      rendKey:'0'
    }
  },
  watch: {
    $route(to, from) {
      console.log(to.name)
      if(to.name == 'quickOrder'){
        this.isShowfooterMenu = false;
        this.isShowfooterMenu = false;
        this.isSearchCon = false;
        this.isSearchks = true;
      }else if(to.name == 'home' || to.name == 'personalCenter'){
        this.isSearchks = false;
        this.isSearchCon = true;
        this.isShowmainMenu = true;
        this.isShowfooterMenu = true;
      }else if(to.name == 'myOrderList'){
        this.isSearchks = false;
        this.isShowmainMenu = false;
        this.isSearchCon = false;
        this.isShowfooterMenu = false;
      }else if(to.name == 'checkOrderInfo'){
        this.isSearchks = false;
        this.isSearchCon = false;
        this.isShowmainMenu = false;
        this.isShowfooterMenu = false;
      }else if(to.name == 'usuallyBuy' || to.name == 'collectionGoods' ){
        this.isShowfooterMenu = false;
      }else if(to.name == 'paySelect' || to.name == 'payment' || to.name == 'paySuccess'){
        this.isShowmainMenu = false;
        this.isShowfooterMenu = false;
        this.isSearchCon = false;
        this.isSearchks = false;
      }else if(to.name == 'protocol'){
        this.isShowmainMenu = false;
        this.isShowfooterMenu = true;
        this.isSearchCon = false;
        this.isSearchks = false;
      }else{
        this.isSearchCon = true;
        this.isSearchks = false;
        this.isShowfooterMenu = false;
        this.isShowfooterMenu = true;
        this.isShowmainMenu = true;
      }
    }
  },
  created(){
    apiModel.myProfileDatas({}).then(res => {
        localStorage.setItem('fullUserInfo',JSON.stringify(res.data))
      })
  },
};
</script>
<style lang="less" scoped>
.centerPages{
  min-height: calc(100vh - 354px);
}
</style>
