<template>
  <div>
    <div class="home_top">
      <div class="content">
        <goodClassify :categoryList="categoryList" />
        <bannerCarousel :bannerList="bannerList" />
      </div>
    </div>
    <div class="home_bottom">
      <div class="content">
        <div class="goodsTitle">
          <div>
            <img src="@/assets/img/times.png" alt="" />
            <span class="titles">常购商品</span>
          </div>
          <div>
            <span class="pages">1/2</span>
            <img src="@/assets/img/prev.png" alt="" />
            <img src="@/assets/img/next.png" alt="" />
          </div>
        </div>
        <div class="goodsList">
          <goodsItem type='cg' :dataList="oftenBuyPageDataList" />
        </div>
        <img class="banner_gg" src="@/assets/img/gg.png" alt="" />
        <div class="goodsTitle">
          <div>
            <img src="@/assets/img/hots.png" alt="" />
            <span class="titles">爆款专区</span>
          </div>
          <!-- <div>
            <span class="pages">1/2</span>
            <img src="@/assets/img/prev.png" alt="">
            <img src="@/assets/img/next.png" alt="">
          </div> -->
        </div>
        <div class="goodsList">
          <goodsItem type='bk' :dataList="recommendedPageDataList" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiModel from "@/api";
import goodClassify from "@/components/goodClassify.vue";
import bannerCarousel from "@/components/bannerCarousel.vue";
import goodsItem from "@/components/goodsItem.vue";
export default {
  name: "HomeView",
  components: {
    goodClassify,
    bannerCarousel,
    goodsItem,
  },
  data() {
    return {
      categoryList: [],
      bannerList: [],
      fullGiftList: [],
      oftenBuyPageDataList: [],
      recommendedPageDataList: [],
    };
  },
  async created() {
    this.getListAllTree();
    const fullGiftList = await apiModel.getFullGiftList();
    this.fullGiftList = fullGiftList.data;
    this.shopPage();
    this.oftenBuyPageData();
    this.recommendedPageData();
  },
  mounted() {},
  methods: {
    getListAllTree() {
      apiModel.listAllTree({ categoryType: 0 }).then((res) => {
        // console.log("listAlllistAll==>", res.data);
        this.categoryList = res.data;
      });
    },
    shopPage() {
      apiModel.shopPage().then((res) => {
        // console.log("shopPage==>", res.data);
        let responseData = res.data;
        this.bannerList = responseData.pageData.items[1].data;
      });
    },
    oftenBuyPageData() {
      apiModel.oftenBuyPageData().then((res) => {
        this.oftenBuyPageDataList = res.data.list;
        this.oftenBuyPageDataList = this.oftenBuyPageDataList.map((item) => {
          if(this.fullGiftList.findIndex(item2 => item.productSkuDTO.productSkuId == item2) != -1){
            item.isFullGift = true;
          }else{
            item.isFullGift = false;
          }
          return item
        });
        // this.oftenBuyPageDataList = this.oftenBuyPageDataList.filter(item=>item.isControlled);
      });
    },
    recommendedPageData() {
      apiModel.recommendedPageData().then(async (res) => {
        this.recommendedPageDataList = res.data.list;
        this.recommendedPageDataList = this.recommendedPageDataList.map((item) => {
          if(this.fullGiftList.findIndex(item2 => item.productSkuDTO.productSkuId == item2) != -1){
            item.isFullGift = true;
          }else{
            item.isFullGift = false;
          }
          return item
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.home_top {
  width: 100%;
  height: 384px;
  min-width: @base-width;
  display: flex;
  background: #fff;
  .content {
    width: @base-width;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    position: relative;
  }
}
.home_bottom {
  background: #f8f8f8;
  height: 100%;
  .content {
    width: @base-width;
    height: 100%;
    margin: 0 auto;
    padding-bottom: 30px;
  }
  .goodsTitle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    flex-shrink: 0;
    .titles {
      font-size: 24px;
      font-family: "AlibabaPuHuiTiM";
      color: #222222;
      font-weight: bold;
    }
    div {
      flex-shrink: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .pages {
        color: #97a0aa;
      }
      span {
        margin: 0 10px;
      }
    }
  }
}
.goodsList {
  div {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
.banner_gg {
  width: 1200px;
  height: 120px;
}
</style>
