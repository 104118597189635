<template>
  <div class="search">
    <div class="content">
      <img class='loginIcon'  @click="toIndex" src="@/assets/img/login.png" alt="">
      <div class="searchInput">
        <div class="leftCon">
          <!-- <div class="flexDiv" @click="openTables"> -->
          <div class="flexDiv">
            <img src="@/assets/img/searchIcon.png" alt="">
            <el-input placeholder='搜索药品/品牌名称' @keyup.enter.native="searchGoods" clearable v-model="queryData.search" type="text" />
          </div>
          <span @click="searchGoods">搜索</span>
          <div class="searchTables" v-if="showThis">
            <div class="tablesDiv">
              <el-table ref="multipleTable" :data="tableData" :highlight-current-row="false" tooltip-effect="dark"  style="width: 100%"  
                @selection-change="handleSelectionChange">
                <el-table-column type="selection"  width="55"></el-table-column>
                <el-table-column prop="productName"  label="商品名称"  width="150" show-overflow-tooltip></el-table-column>
                <el-table-column  prop="productSkuDTO.properties.value" label="规格" width="120"> </el-table-column>
                <el-table-column  prop="productSkuDTO.salePrice" label="单价" show-overflow-tooltip> </el-table-column>
                <el-table-column  prop="productBatchNumber.stock" label="库存" show-overflow-tooltip> </el-table-column>
              </el-table>
            </div>
            <div class="optsBtn">
              <button class="cancelBtn curPointer" @click="showThis = false">取消</button>
              <button class="sureBtn curPointer" @click="selectGoods">确定</button>
            </div>
          </div>
        </div>
        <div class="rightCon" @click="toOrderList">
          <img src="@/assets/img/orderIcon.png" alt="">
          <span>订购清单</span>
          <span>{{goodsNum}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/utils/bus";
import apiModel from '@/api';
export default {
  name: "searchCon",
  props: {
    msg: String,
  },
  data(){
    return{
      showThis:false,
      goodsNum: 0,
      selectGoodsList: [],
      queryData: {
        search: '',
        pageNumber: 1,
        pageSize: 100000,
      },
      tableData: [],
    }
  },
  created(){
    this.queryData.search = this.$route.query.searchValue;
    if (this.queryData.search) {
      this.searchGoods()
    }
    this.goodsNum = localStorage.getItem('orderCarList') ? JSON.parse(localStorage.getItem('orderCarList')).length : 0
  },
  mounted(){
    EventBus.$on("goodsLength", goodsLength => {
        this.goodsNum = goodsLength;
      })
  },
  methods:{
    selectGoods(){
      EventBus.$emit("selectGoodsList", this.selectGoodsList);
      this.showThis = false;
    },
    searchGoods(){
      this.showThis = true;
      this.getGoodsList();
    },
    handleSelectionChange(val){
      this.selectGoodsList = val
    },
    getGoodsList(){
      this.tableData = [];
      apiModel.searchPageData(this.queryData).then(res => {
        let datas = res.data.list;
        datas.forEach(item => {
          if(item.productSkuDTO.accordScope && item.productSkuDTO.policyScope){
            console.log(1212)
            this.tableData.push(item)
          }
        });
        console.log(this.tableData)
      })
    },
    openTables(){
      this.showThis = true;
    },
    toIndex(){
      this.$router.push({
        path:"/home"
      })
    },
    toOrderList(){
      this.$router.push("/MyOrderList");
    },
  }
};
</script>
<style lang="less" scoped>
  .search{
    height: 96px;
    background: #fff;
    width: 100%;
    display: flex;
    min-width: @base-width;
    align-items: center;
    position: relative;
    .content{
      width: @base-width;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .loginIcon{
        width: 174px;
        height: 47px;
        cursor: pointer;
      }
      .searchInput{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 20px;
        .leftCon{
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 36px;
          width: 600px;
          border-radius: 2px;
          border: 2px solid @base-color;
          box-sizing: border-box;
          padding-left: 14px;
          position: relative;
          div.flexDiv{
            width: calc(100% - 103px);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            /deep/.el-input__inner{
              padding: 0 10px;
              height: 33px;
              line-height: 31px;
              width: 100%;
              border:none;
            }
            input::-webkit-input-placeholder{ /* WebKit browsers */
              color: #B2BECB;
            }
            input:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
              color: #B2BECB;
            }
            input::-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
              color: #B2BECB;
            }
            input::-ms-input-placeholder{/* Internet Explorer 10+ */
              color: #B2BECB;
            }
          }
          span{
            position: absolute;
            right: -2px;
            top: -1px;
            display: inline-block;
            background: @base-color;
            width: 103px;
            height: 33px;
            line-height: 33px;
            font-size: 16px;
            color:#fff;
            text-align: center;
            border-bottom-right-radius: 2px;
            border-top-right-radius: 2px;
            cursor: pointer;
            &:hover{
              background:@base-hcolor;
            }
          }
        }
        .rightCon{
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 132px;
          height: 36px;
          background: #FEEEE9;
          color: #FC5D2C;
          box-sizing: border-box;
          margin-left: 12px;
          padding: 15px;
          cursor: pointer;
          img{
            margin-right: 6px;
          }
          span{
            margin-right: 6px;
            &:nth-child(3){
              color:#fff;
              background: #FC5D2C;
              border-radius:20px;
              padding: 2px 8px;
            }
          }
        }
      }
    }
  }
.searchTables{
  position:absolute;
  top: 35px;
  left: 0;
  height: 300px;
  width: 580px;
  z-index: 9;
  padding: 10px;
  background: #fff;
  box-shadow: 4px 4px 12px 4px rgba(58, 62, 67, 0.1);
  animation-duration: 10s;
  animation-fill-mode: both;
  .tablesDiv{
    min-height: 260px;
    max-height:260px;
    overflow: auto;
  }
  .optsBtn{
    text-align: right;
    margin-top: 10px;
  }
  .sureBtn{
    width: 80px;
    height: 32px;
    background: linear-gradient(34deg, #28C698 0%, #3DDBC3 100%);
    border-radius: 2px;
    border:none;
    font-size:14px ;
    color: #fff;
    margin-left: 20px;
    cursor: pointer;
  }
  .cancelBtn{
    width: 80px;
    height: 30px;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #333;
    font-size:14px ;
    color: #5D636F;
    cursor: pointer;
    box-sizing: border-box;
  }
}
</style>