<template>
  <div class="footer">
    <div class="fooerThree">
      <ul>
        <li>
          <img src="@/assets/img/cf1.png" alt="">
          <div class="words">
            <p>品质:100%正品</p>
            <p>每个产品都可追根溯源</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/cf2.png" alt="">
          <div class="words">
            <p>价格:低于同类30%以上</p>
            <p>自营平台价格更有优势</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/cf3.png" alt="">
          <div class="words">
            <p>服务:7x24小时服务</p>
            <p>一对一专业销售顾问为您服务</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/cf4.png" alt="">
          <div class="words">
            <p>发货:最快1天可到达</p>
            <p>快递配送，下单立即发货</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="footerTwo">
      <div class="footerMinWid">
         <div class="footer-left">
          <div>
            <p>帮助中心</p>
            <p class="btnp" @click="gotoSalesRules">售后服务</p>
          </div>
          <div>
            <p>法律服务</p>
            <p class="btnp" @click="gotoProtocol">隐私协议</p>
          </div>
          <div>
            <p>投诉</p>
            <p>投诉邮箱：eric@dinuo.com.cn</p>
            <p>意见反馈</p>
          </div>
        </div>
        <div class="footer-right">
          <div>
            <p>微信小程序</p>
            <img src="@/assets/img/xcx.jpg" alt="">
          </div>
          <div>
            <p>微信公众号</p>
            <img src="@/assets/img/ewm.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="footerOne">统一社会信用代码/注册号：91430181MA4Q6QAEX1 互联网药品信息服务资格证书：（湘）-经营性-2021-0089号 网站备案/许可证号：湘ICP备2021012824号-1
      <div style="color: #fff;font-size: 12px;margin-left: 10px">v{{version}}</div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: "footerMenu",
  props: {},
  data(){
    return{
      version: process.env.VUE_APP_VERSION
     
    }
  },
  methods:{
    gotoProtocol(){
      this.$router.push('/Protocol');
    },
    gotoSalesRules(){
      this.$router.push('/PersonalCenter/SalesRules');
    }
  }
};
</script>
<style lang="less" scoped>
  .footer{
    .footerOne{
      width: 100%;
      min-width: @base-width;
      height: 42px;
      line-height: 42px;
      background: #3A3E43;
      font-size: 12px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #FFFFFF;
      text-align: center;
      border-top:1px solid rgba(255,255,255,0.1);
      display: flex;
      justify-content: center;
    }
    .footerTwo{
      width: 100%;
      min-width: @base-width;
      margin: 0 auto;
      background: #3A3E43;
      font-size: 12px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #FFFFFF;
      text-align: center;
      display: flex;
      .footerMinWid{
        width: @base-width;
        height: 160px;
        margin: 0 auto;
        padding: 24px 0;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        .footer-left{
          display: flex;
          justify-content: space-around;
          .btnp{
            &:hover{
              cursor: pointer;
              border-bottom: 1px solid #fff;
            }
          }
          div{
            margin-right: 184px;
            text-align: left;
            p{
              &:nth-child(1){
                font-size: 14px;
                font-family: AlibabaPuHuiTiM;
                color: #FFFFFF;
                margin-bottom: 12px;
              }
              &:nth-child(2){
                color: #FFFFFF;
                margin-bottom: 12px;
              }
            }
          }
        }
        .footer-right{
          display: flex;
          justify-content: space-around;
          div{
            margin-right: 40px;
            width: 80px;
            text-align: center;
            p{
              margin-bottom: 12px;
              font-size: 14px;
            }
            img{
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }
    .fooerThree{
      width: 100%;
      min-width: @base-width;
      height: 108px;
      background: url('@/assets/img/footer3.png')no-repeat;
      background-size: 100% 108px;
      position: relative;
      display: flex;
      ul{
        width: 1400px;
        height: 108px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        li{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color:#fff;
          img{
            margin-right: 12px;
          }
          .words{
            line-height: 25px;
            p:nth-child(1){
              font-family: "AlibabaPuHuiTiM";
              font-size: 14px;
              font-weight: bold;
            }
             p:nth-child(2){
              font-size: 12px;
              font-family: "Alibaba-PuHuiTi-R, Alibaba-PuHuiTi";
              font-weight: normal;
            }
          }
        }
      }
    }
    
  }
  ul,li{
      list-style: none;
    }
</style>