import Vue from "vue";
import Vuex from "vuex";
import * as types from "./types"
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    crumbList:{}
  },
  mutations: { // 修改数据状态
    [types.CRMB]: (state, datas) => {
      // console.log("修改数据状态",datas)
      state.crumbList = datas;
      window.sessionStorage.setItem("crumbList",JSON.stringify(datas))
    },
  },
  getters: { // 取数据
    crumbList:state => state.crumbList
  },
  actions: { // 触发action 
    [types.CRMB]({commit,state}, params){ // 登录action
      commit(types.CRMB,params)
    }
  },
  modules: {},
});
