import config from "../config"
import request from "../utils/request"
import {download} from "../utils/request"
const apiModel = {
    // 获取所有api前缀
    appVersionInfo: (params,type) => request({
        url: config.apiPrefixPath + config.appVersionInfo,
        method: type || 'get',
        data: params
    }),
    // 将json传值转化为formData
    formatFormDataParams: (params) => {
        const formatParams = new URLSearchParams();
        for (var key in params) {
            formatParams.append(key, params[key])
        }
        return formatParams
    },

    formatFormDataParams1:(params)=> { 
      let result = ''
      for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                let params = propName + '[' + key + ']';
                var subPart = encodeURIComponent(params) + "=";
                result += subPart + encodeURIComponent(value[key]) + "&";
              }
            }
          } else {
            result += part + encodeURIComponent(value) + "&";
          }
        }
      }
      return result
    },
    getUserInfoFromStorage: (key) => new Promise((resolve, reject) => {
        var curStorageInfo = localStorage.getItem(key) || ''
        var formatRequestUrlData = curStorageInfo ? JSON.parse(curStorageInfo) : {}
        if (JSON.stringify(formatRequestUrlData) !== '{}') {
            resolve(formatRequestUrlData)
        } else {
            apiModel.myProfileDatas({}).then(res => {
              resolve(res.data)
            })
        }
    }),
    // 根据业务类型key从本地存储中获取对应的api前缀
    getApiPrefixFromStorage: (key) => new Promise((resolve, reject) => {
        var requestUrlData = localStorage.getItem('requestUrlData') || ''
        var formatRequestUrlData = requestUrlData ? JSON.parse(requestUrlData) : {}
        if (formatRequestUrlData[key]) {
            resolve(formatRequestUrlData[key])
        } else {
            reject({msg: '获取api前缀错误'})
        }
    }),
    // ------------首页接口 start----------------
    // 查询所有分类信息
    listAll: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/category/listAll?categoryType=0",
            method: type || 'get',
            data: params
        })
    },
    // 查询所有分类信息树结构
    listAllTree: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/category/listAllTree?categoryType=0",
            method: type || 'get',
            data: params
        })
    },
    // 查询 轮播 /导航组 /logo/
    shopPage: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/shopPage/get?id=10000",
            method: type || 'get',
            data: params
        })
    },
    // 常购商品
    oftenBuyPageData: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/product/oftenBuyPageData",
            method: type || 'get',
            data: params
        })
    },
    // 获取商品详情
    getProductDetail: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/product/selectProduct?id=" + params.id,
            method: type || 'get',
            data: params
        })
    },

    // 首页爆款商品列表
    recommendedPageData: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/product/recommendedPageData",
            method: type || 'get',
            data: params
        })
    },
    // 购物车列表
    cartList: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/product/getProductBySkuIds?skuIds="+params,
            method: type || 'get',
            data: {}
        })
    },
    // 查询所有符合满赠商品的列表
    getFullGiftList: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/pricePolicy/getSendProductSkuIds",
            method: type || 'get',
            data: params
        })
    },
    // 符合政策赠品的商品及政策
    selectPricePolicyCustomerProduct: async (params,type) => { //productSkuId
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/pricePolicy/selectPricePolicyCustomerProduct",
            method: type || 'get',
            data: params
        })
    },
    // 查询商品价格
    queryPrice: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/purchase/queryPrice",
            method: type || 'post',
            data: {...params, isError: 'NO'}
        })
    },
    // 保存订单
    buySave: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/orderInfo/buySave",
            method: type || 'post',
            data: params
        })
    },

    // 订单状态
    orderStatus: async (params,type) => { // 返回订单状态:1待支付、2取消支付、3支付中、4支付完成、5待发货、6已发货、7订单完成
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/orderInfo/state?id="+params.orderId,
            method: type || 'get'
        })
    },
    // 收货地址
    addressList: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/customerAddress/get",
            method: type || 'get',
            data: params
        })
    },
    // 订单详情
    orderDetail: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/orderInfo/get?id="+params.id,
            method: type || 'get',
            data: params
        })
    },
    // 支付订单
    orderInfoPay: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + 
            `/api/front/orderInfo/wxlite/pay?balancePayMoney=${params.balancePayMoney}&body=${params.body}&channelExtra=0&currency=cny&mchOrderNo=${params.mchOrderNo}&subject=${params.subject}&wayCode=${params.wayCode}
            `,
            method: type || 'post',
            data: params
        })
    },
    // 获取微信付款码 (废弃，前端自己生成)
    getWxPayCode: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/tool/systemParam/convert/qrcode?text="+params.text,
            method: type || 'get',
            data: params
        })
    },
    // 订单列表
    orderList: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/orderInfo/pageWbList",
            method: type || 'get',
            params: params
        })
    },
    // 取消订单
    orderCancel: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/orderInfo/cancelById?id="+params.id,
            method: type || 'post',
            data: params
        })
    },
    // 删除订单
    orderDelete: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/orderInfo/deleteById?id="+params.id,
            method: type || 'post',
            data: params
        })
    },
    // 统计订单数量
    orderCount: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/orderInfo/customerOrdercount",
            method: type || 'get',
            data: params
        })
    },
    // 下载首营资料批量
    downloadProductInformationFiles_pl: async (params,type) => {
      let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
      return download(
          apiPrefixPath + "/api/front/orderInfo/pc/downloadProductInformationFiles?orderId="+params.orderId,
          params.filename
      )
    },
    // 下载 批量导出商品厂检报告
    downloadProductReportFiles: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return download(
            apiPrefixPath + "/api/front/orderInfo/pc/downloadProductReportFiles?orderId="+params.orderId,
            params.filename
        )
    },

    // 下载单个
    downloadProductReportFile: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return download(
          // /api/front/orderInfo/pc/downloadDeliverReportFiles
            apiPrefixPath + "/api/front/orderInfo/pc/downloadDeliverReportFiles?productId="+params.productId,
            params.filename
        )
    },
    // 下载单个产品的首营资料
    downloadProductInformationFiles: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return download(
            apiPrefixPath + "/api/front/product/downloadProductInformationFiles?productId="+params.productId,
            params.filename
        )
    },

    // 根据批次主键导出批次厂检报告
    downloadBatchNumberReportFiles: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return download(
            apiPrefixPath + "/api/front/product/downloadBatchNumberReportFiles?productBatchNumberId="+params.productBatchNumberId,
            params.filename
        )
    },
    // 快递查询
    expressQuery: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/orderDeliver/list",
            method: type || 'get',
            params: params
        })
    },

    // 宣传物料
    materialPageData: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/product/materialPageData",
            method: type || 'get',
            params: params
        })
    },
    // 根据指定分类查询商品
    categoryPageData: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/product/categoryPageData",
            method: type || 'get',
            params: params
        })
    },
    // 所有满赠政策赠品的商品
    sendPageData: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/product/sendPageData",
            method: type || 'get',
            params: params
        })
    },
    // 全局搜索指定产品
    searchPageData: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/product/searchPageData",
            method: type || 'get',
            params: params
        })
    },
    // 商品收藏
    productCollection: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/productCollection/saveByProductId",
            method: type || 'post',
            params: params
        })
    },
    // 我的收藏
    myProductCollection: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/productCollection/pageList",
            method: type || 'get',
            params: params
        })
    },
    // 消息中心 name：名称，messageType：消息类型，size：未读数量，desc:最新消息内容，createTime:最新消息时间
    getMessageList: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/magnification/getMessageList",
            method: type || 'get',
            params: params
        })
    },
    // 消息列表
    getMessageListByType: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/magnification/pageList",
            method: type || 'get',
            params: params
        })
    },

    // 消息已读 messageType:1:系统消息 2:订单消息 3:订单提醒
    readMessage: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/magnification/batchupdate",
            method: type || 'post',
            params: params
        })
    },
    // 充值消费记录
    rechargePageData: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/customerFundFlow/pageWbList",
            method: type || 'get',
            params: params
        })
    },
    // 客户详情
    customerDetail: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/user/getDetail",
            method: type || 'get',
            params: params
        })
    },
    // 经营范围
    businessScope: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/business/scope/searchListAll",
            method: type || 'get',
            params: params
        })
    },
    // 查询商品所有批次库存及厂检报告
    productAllBatch:  async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/product/productBatchNumber",
            method: type || 'get',
            params: params
        })
    },
    // 修改用户名
    updateUserName:  async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appAuthUrl')
        return request({
            url: apiPrefixPath + "/realms/dinuo/user-common-resource/changeUsername",
            method: type || 'get',
            params: params
        })
    },
    // 更新头像信息
    updateUserHeadImg: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appAuthUrl')
        return request({
            url: apiPrefixPath + "/realms/dinuo/user-common-resource/updateAvatar",
            method: type || 'get',
            params: params
        })
    },

    // https://api.dinuo.com.cn/business/api/tool/systemParam/get?paramName=CUSTOMER_SERVIZIO_ACCORDO
    //用户隐私协议 CUSTOMER_PRIVACY_ACCORDO 森迪医药商城用户服务协议 CUSTOMER_SERVIZIO_ACCORDO  关于森迪 ABOUT_SENDI
    getXyData: async (params,type) => {
      let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
      return request({
          url: apiPrefixPath + "/api/tool/systemParam/get?paramName=CUSTOMER_SERVIZIO_ACCORDO",
          method: type || 'get',
          params: params
      })
    },

    //用户隐私协议 CUSTOMER_PRIVACY_ACCORDO 森迪医药商城用户服务协议 CUSTOMER_SERVIZIO_ACCORDO  关于森迪 ABOUT_SENDI
    getSystemParam: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/systemParam/get",
            method: type || 'get',
            params: params
        })
    },
    // 查询商品所有批次库存及厂检报告
    productBatchNumber: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/product/productBatchNumber",
            method: type || 'get',
            params: params
        })
    },
    // 退出登录
    logout:  async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appAuthUrl')
        return request({
            url: apiPrefixPath + "/realms/dinuo/protocol/openid-connect/logout",
            method: type || 'get',
            params: params
        })
    },
     // 修改密码
    resetPassword:  async (params,type) => {
      let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appAuthUrl')
      return request({
          url: apiPrefixPath + "/realms/dinuo/user-common-resource/changePassword",
          method:'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          data: apiModel.formatFormDataParams(params)
      })
    },
    changeUsername:  async (params,type) => {
      let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appAuthUrl')
      return request({
          url: apiPrefixPath + "/realms/dinuo/user-common-resource/changeUsername",
          method:'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          data: apiModel.formatFormDataParams(params)
      })
    },
    // 数据字典
    dictDateList: async (params,type) => {
      let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
      return request({
          url: apiPrefixPath + "/api/front/dictData/dictDateList",
          method:'get',
          params: params
      })
    },


 // ------------首页接口 end----------------

   // ------------个人中心接口 start----------------
   myProfileDatas: async (params,type) => {
        let apiPrefixPath = await apiModel.getApiPrefixFromStorage('appBaseUrl')
        return request({
            url: apiPrefixPath + "/api/front/customer/myProfile",
            method: type || 'get',
            data: params
        })
    },

   // ------------个人中心接口 end---------------

    
    
}
export default apiModel